<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <!-- <el-form-item label="选择楼栋">
				<el-cascader v-model="houseArr_queryForm" :options="houseCascaderData" :props="cascaderProps" clearable
					@change="handleCascaderChange_queryForm" class="el-input_inner--rewrite"></el-cascader>
			</el-form-item> -->
      <el-form-item label="交班时间" prop="date_range">
        <el-date-picker v-model="queryForm.date_range" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="交班人" prop="adminer">
        <el-input v-model="queryForm.adminer"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset" type="infor">重置</el-button>
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      border stripe size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <!-- <el-table-column label="楼栋" prop="building.name"></el-table-column> -->
      <el-table-column label="交班时间" prop="shift_time"></el-table-column>
      <el-table-column label="会员重要情况记录" prop="situation"></el-table-column>
      <el-table-column label="周知事宜" prop="inform"></el-table-column>
      <el-table-column label="交班人" prop="adminer"></el-table-column>
      <el-table-column label="角色">
        <template v-slot="{ row }">
          <span>{{ row.user.roles[0] ? row.user.roles[0].title : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getDutyData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑表单的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <!-- <el-form-item label="选择楼栋" prop="houseArr">
					<el-cascader v-model="form.houseArr" :options="houseCascaderData" :props="cascaderProps" clearable
						class="el-input_inner--rewrite"></el-cascader>
				</el-form-item> -->
        <el-form-item label="交班时间" prop="shift_time">
          <el-date-picker v-model="form.shift_time" type="date" value-format="yyyy-MM-dd"
            placeholder="选择日期时间" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="rolesId" filterable clearable placeholder="请选择" @change="rolesChange"
            class="el-input_inner--rewrite">
            <el-option v-for="item in rolesData" :key="item.id" :label="item.title" :value="item.id">
              <span>{{ item.title }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="值班人" prop="user_id">
          <el-select v-model="form.user_id" filterable clearable placeholder="请选择" class="el-input_inner--rewrite">
            <el-option v-for="item in userData" :key="item.id" :label="item.name" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.name }}</span>
              </div>
            </el-option>
            <el-pagination small layout="prev, pager, next, total" :total="userTotal"
              :current-page.sync="userQuery.page" :page-size="userQuery.page_size" @current-change="getUsers">
            </el-pagination>
          </el-select>
        </el-form-item>
        <el-form-item label="会员重要情况记录" prop="situation">
          <el-input v-model="form.situation" type="textarea" :rows="3"></el-input>
        </el-form-item>
        <el-form-item label="周知事宜" prop="inform">
          <el-input v-model="form.inform" type="textarea" :rows="3"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button @click="handleSubmit" :loading="formSubmitting" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 预览信息的弹窗 -->
    <el-dialog title="查看" :visible.sync="previewDialog" width="30%">
      <el-descriptions v-if="curRowData !== null" :column="1" border>
        <!-- <el-descriptions-item label="园区">{{ curRowData.building.area.name }}</el-descriptions-item>
				<el-descriptions-item label="楼栋">{{ curRowData.building.name }}</el-descriptions-item> -->
        <el-descriptions-item label="交班时间">{{ curRowData.shift_time }}</el-descriptions-item>
        <el-descriptions-item label="会员重要情况纪录">{{ curRowData.situation }}</el-descriptions-item>
        <el-descriptions-item label="周知事宜">{{ curRowData.inform }}</el-descriptions-item>
        <el-descriptions-item label="值班人">{{ curRowData.adminer }}</el-descriptions-item>
        <el-descriptions-item
          label="角色">{{ curRowData.user.roles[0] ? curRowData.user.roles[0].title : '' }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="previewDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getHouseCascaderDataAPI,
  } from '@/api/base-data.js'
  import {
    saveDutyAPI,
    getDutyDataAPI,
    delDutyAPI,
    getUsersAPI,
    getRolesAPI,
  } from '@/api/duty.js'

  import {
    formatDate
  } from '@/utils/common-fun.js'
  export default {
    name: 'Duty',
    data() {
      return {
        houseCascaderData: [],
        cascaderProps: {
          label: 'name',
          value: 'id'
        },

        // 搜索
        houseArr_queryForm: [],
        queryForm: {
          page: 1,
          home_building_id: '',
          date_range: '',
          adminer: ''
        },

        // 表格
        tableData: [],
        total: 0,
        pageSize: 0,
        curRowData: null,
        tableLoading: false,

        // 查看表单
        previewDialog: false,

        // 表单
        showDialog: false,
        dialogTitle: '',
        formSubmitting: false,
        form: {
          // houseArr: [],
          shift_time: '',
          situation: '',
          inform: '',
          user_id: '', // 值班人
        },
        rules: {
          // houseArr: [{
          // 	required: true,
          // 	message: '楼栋不能为空',
          // 	trigger: 'change'
          // }],
          shift_time: [{
            required: true,
            message: '交班时间不能为空',
            trigger: 'change'
          }],
          user_id: [{
            required: true,
            message: '值班人不能为空',
            trigger: 'change'
          }],
        },

        // 获取用户请求参数
        userQuery: {
          role_id: '',
          keyword: '',
          page: 1,
          page_size: 10,
        },
        // 用户列表
        userData: [],
        // 用户总数
        userTotal: 1,


        // 角色列表
        rolesData: [],
        // 选中的角色
        rolesId: '',
      }
    },
    created() {
      this.getDutyData()
      this.getHouseCascaderData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
        this.rolesId = ''
        let shift_time = formatDate(new Date());
        this.form.shift_time = shift_time.split(' ')[0] + ' 08:00:00';
        this.getRoles();
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getDutyData()
      },
      // 重置按钮
      handleReset() {
        this.houseArr_queryForm = []
        this.$refs.queryForm.resetFields()
      },
      // 搜索表单的级联改变 --设置 queryForm.home_building_id
      handleCascaderChange_queryForm(e) {
        if (e.length > 1) this.queryForm.home_building_id = e[e.length - 1]
        else this.queryForm.home_building_id = ''
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        this.getRoles();
        if (this.curRowData.user.roles[0]) {
          this.userQuery.role_id = this.curRowData.user.roles[0].id;
          this.rolesId = this.userQuery.role_id;
          this.getUsers();
        } else {
          this.rolesId = '';
          this.userQuery.role_id = '';
        }
        // 设置 form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 查看按钮
      handleView(row) {
        this.previewDialog = true
        this.curRowData = row
      },
      // 删除按钮
      handleDel(id) {
        delDutyAPI({
          id,
        }).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getDutyData()
          }
        })
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return
          // if (this.form.houseArr.length < 2) return this.$message.error('未选择到楼栋，请检查')

          this.formSubmitting = true
          const data = {
            // home_building_id: this.form.houseArr[1],
            shift_time: this.form.shift_time,
            situation: this.form.situation,
            inform: this.form.inform,
            user_id: this.form.user_id
          }
          if (this.dialogTitle === '编辑') data.id = this.curRowData.id
          // 提交
          saveDutyAPI(data).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getDutyData()
          }).finally(() => this.formSubmitting = false)
        })
      },
      // 获取值班数据
      getDutyData() {
        this.tableLoading = true

        getDutyDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
      // 获取级联数据（获取到楼栋）
      getHouseCascaderData() {
        getHouseCascaderDataAPI({
          level: 1
        }).then(res => {
          this.houseCascaderData = res.data
        })
      },
      // 获取用户
      getUsers() {
        getUsersAPI(this.userQuery).then(res => {
          this.userTotal = res.total;
          this.userData = res.data;
        })
      },
      // 获取角色
      getRoles() {
        getRolesAPI().then(res => {
          this.rolesData = res;
        })
      },
      // 切换角色
      rolesChange(e) {
        this.userQuery.role_id = e;
        this.getUsers();
      }
    }
  }
</script>

<style scoped>
</style>